import store from '../store';
import axios from 'axios';

class ReferralService {
    getByUserId(user_id){
        let data = {
            params: {
                user_id: user_id
            },
            headers: {
                "x-user-id": store.state.user.id,
                'x-user-mail': store.state.user.email,
                "content-type": "application/json"
            }
        };

        return axios.get(store.state.api_url + 'referral/', data).then(response => {
            return Promise.resolve(response.data.referral_code);
        }).catch (error => {
            return Promise.reject(error);
        });
    };

    // getUsersByReferredCode(referred_code){
    //     let data = {
    //         params: {
    //             referred: referred_code
    //         },
    //         headers: {
    //             "x-user-id": store.state.user.id,
    //             'x-user-mail': store.state.user.email,
    //             "content-type": "application/json"
    //         }
    //     };

    //     return axios.get(store.state.api_url + 'user/get', data).then(response => {
    //         return Promise.resolve(response.data.referral_code);
    //     }).catch (error => {
    //         return Promise.reject(error);
    //     });
    // }
}

export default new ReferralService();