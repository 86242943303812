<template>
    <li class="menu single-menu">
        <a href="javascript:;" class="dropdown-toggle">
            <div class="">
                <svg width="800px" height="800px" viewBox="0 0 128 128" id="Слой_1" version="1.1" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                    
                    <g>
                        <g>
                            <circle class="st0" fill= "#ffe0b2" cx="64" cy="33" r="31" />

                            <path class="st1" fill= "#424242" d="M64,66c-18.2,0-33-14.8-33-33S45.8,0,64,0s33,14.8,33,33S82.2,66,64,66z M64,4C48,4,35,17,35,33s13,29,29,29    s29-13,29-29S80,4,64,4z" />
                        </g>

                        <path class="st2" fill= "#90caf9" d="M126,126c0-34.2-27.8-62-62-62S2,91.8,2,126H126z" />

                        <path
                            class="st1"
                            d="M126,128H2c-1.1,0-2-0.9-2-2c0-25.9,15.5-48.3,37.7-58.3c8-3.6,17-5.7,26.3-5.7c6.6,0,13,1,19,2.9   c26,8.1,45,32.4,45,61.1C128,127.1,127.1,128,126,128z M4,124h60h60c-0.6-19.4-10.5-36.4-25.3-46.9c-6.1-4.4-13.1-7.6-20.6-9.4   C73.5,66.6,68.8,66,64,66c-5.9,0-11.6,0.9-17,2.4c-5,1.5-9.7,3.6-14,6.2C16.2,84.8,4.7,103.1,4,124z"
                        />
                    </g>

                    <path class="st3" fill= "#ffffff" d="M56,127" />
                </svg>
                <span>Account</span>
            </div>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-chevron-down"
            >
                <polyline points="6 9 12 15 18 9"></polyline>
            </svg>
        </a>
        <ul class="collapse submenu list-unstyled">
            <li><router-link to="/account/profil">Profile</router-link></li>
            <li><router-link to="/account/kyc">KYC - Documents</router-link></li>
        </ul>
    </li>
</template>
