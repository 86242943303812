import store from '../store';
import { useToast } from 'vue-toastification';
import { onErrorCaptured } from 'vue';
const toast = useToast();

class ErrorService {
    handle(error) {
        if (error.response) {
            if (error.response.data.code) {
                toast.error(`${error.response.data.message}\nCode: ${error.response.data.code}`);
            } else if (error.response.status >= 400) {
                toast.error(error.response.data.message);
            } else if (error.response.status >= 500) {
                toast.error('Your request cannot be performed at this time. Please try again after a while.');
            }
        } else {
            console.log(error);
            toast.error('Your request cannot be performed at this time. Please try again after a while.');
        }
    }
}
export default new ErrorService();