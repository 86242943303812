import ReferralService from '../services/referral.service';

export const referral = {
    namespaced: true,
    actions: {
        get({commit}, user_id){
            return ReferralService.get(user_id).then(
                referral_code => {
                    return Promise.resolve(referral_code);
                },
                error => {
                    return Promise.reject(error);
                }
            )
        }
    }
}