<template>
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
        <div class="widget widget-card-three">
            <div class="widget-heading">
                <a href="javascript:void(0)" class="task-info">
                    <svg style="margin-right: 10px" width="64px" height="64px" viewBox="0 0 1024 1024" class="icon"
                        version="1.1" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M810.666667 554.666667m-85.333334 0a85.333333 85.333333 0 1 0 170.666667 0 85.333333 85.333333 0 1 0-170.666667 0Z"
                            fill="#FFCC80" />
                        <path
                            d="M213.333333 554.666667m-85.333333 0a85.333333 85.333333 0 1 0 170.666667 0 85.333333 85.333333 0 1 0-170.666667 0Z"
                            fill="#FFCC80" />
                        <path
                            d="M832 405.333333c0-270.933333-640-177.066667-640 0v213.333334c0 177.066667 142.933333 320 320 320s320-142.933333 320-320V405.333333z"
                            fill="#FFCC80" />
                        <path
                            d="M512 85.333333C324.266667 85.333333 170.666667 238.933333 170.666667 426.666667v74.666666l44.8 12.8V405.333333l416-134.4 174.933333 134.4v108.8l44.8-12.8V426.666667C853.333333 266.666667 738.133333 85.333333 512 85.333333z"
                            fill="#FFCC80" />
                        <path d="M341.333333 512h341.333334v85.333333H341.333333z" fill="#F44336" />
                    </svg>
                    <div style="margin-left: 10px; margin-right: 10px" class="w-title">
                        <h5>KYC Status: Not Approved!</h5>
                    </div>
                </a>
            </div>
            <div class="widget-content">
                <p>{{ content }}</p>
                <div class="progress-data"></div>
            </div>
        </div>
    </div>
</template>

<script setup>
import '@/assets/sass/widgets/widgets.scss';

import userService from '@/services/user.service';
import errorHandleService from '@/services/errorHandle.service';

import { onMounted, computed, provide, ref } from 'vue';
import { useStore } from 'vuex';

const store = useStore();

const content = ref(
    'KYC approval is required in order to deposit and withdraw funds, open a trading account and trade. Please do the necessary steps from the "Account -> KYC - Documents" link in the menu. If you sent your information and necessary documents, you will be contacted as soon as possible.',
);

const showKYC = ref(false);

onMounted(() => {
    
});
</script>
