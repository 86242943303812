import { createApp } from 'vue';
import store from './store';
import App from './App.vue';
import router from './router';

const app = createApp(App);

// bootstrap
import * as bootstrap from 'bootstrap';
window.bootstrap = bootstrap;

// modals
import '@/assets/sass/components/custom-modal.scss';

// perfect scrollbar
import PerfectScrollbar from 'vue3-perfect-scrollbar';
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css';

//vue-meta
import { createHead } from '@vueuse/head';
const head = createHead();

//Sweetalert
import Swal from 'sweetalert2';
window.Swal = Swal;

// Toast
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

// nouislider - later remove and add to page due to not working in page
import VueNouislider from 'vue3-nouislider';
import 'vue3-nouislider/dist/vue3-nouislider.css';

// vue input mask
import Maska from 'maska';

// smooth scroll
import { registerScrollSpy } from 'vue3-scroll-spy/dist/index';
registerScrollSpy(app, { offset: 118 });

//vue-i18n
import i18n from './i18n';

// datatables
import { ClientTable } from 'v-tables-3';

// json to excel
import vue3JsonExcel from 'vue3-json-excel';

//vue-wizard
import VueFormWizard from 'vue3-form-wizard';
import 'vue3-form-wizard/dist/style.css';

// set default settings
import appSetting from './app-setting';
window.$appSetting = appSetting;
window.$appSetting.init();


import axios from 'axios';
const axiosInstance = axios.create({
    withCredentials: true,
    credentials: "include"
});

const roundDownSignificantDigits = (number, decimals=3) => {
    number = Number(number);
    let significantDigits = (parseInt(number.toExponential().split('e-')[1])) || 0;
    let decimalsUpdated = (decimals || 0) + significantDigits - 1;
    decimals = Math.min(decimalsUpdated, number.toString().length);

    return (Math.floor(number * Math.pow(10, decimals)) / Math.pow(10, decimals));
}

const moneyFormatter = (text) => {
    if (text == null) return null;

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',

        // These options are needed to round to whole numbers if that's what you want.
        minimumFractionDigits: 2, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        maximumFractionDigits: 2, // (causes 2500.99 to be printed as $2,501)
    });

    text = roundDownSignificantDigits(text, 3);

    return formatter.format(text);
}

app.config.globalProperties.$turkishtoEnglish = function (turkish_text) {
    if (turkish_text == null) return null;

    return turkish_text.replace('Ğ', 'G')
        .replace('Ü', 'U')
        .replace('Ş', 'S')
        .replace('İ', 'i')
        .replace('Ö', 'o')
        .replace('Ç', 'c')
        .replace('ğ', 'g')
        .replace('ü', 'u')
        .replace('ş', 's')
        .replace('ı', 'i')
        .replace('ö', 'o')
        .replace('ç', 'c');
}

app.use(store)
    .use(router)
    .use(i18n)
    .use(PerfectScrollbar)
    .use(VueNouislider)
    .use(Maska)
    .use(ClientTable)
    .use(vue3JsonExcel)
    .use(VueFormWizard)
    .use(head)
    .use(Toast)
    .provide('axios', axiosInstance)
    .provide('moneyFormatter', moneyFormatter)
    .provide('roundDownSignificantDigits', roundDownSignificantDigits)
    .mount('#app');
