import store from '../store';
import axios from 'axios';
axios.defaults.withCredentials = true;
axios.defaults.credentials = "include";
const axiosInstance = axios.create();

var config = {};
config.baseURL = store.state.api_url;

axiosInstance.interceptors.request.use(createSignature, function (error) {
    return Promise.reject(error);
})

function createSignature(config) {
    config.headers['content-type'] = "application/json";

    if (store.state.user) {
        config.headers['x-user-id'] = store.state.user.id;
        config.headers['x-user-mail'] = store.state.user.email;
    }

    return config;
}


class UserService {
    getUser(user_id) {
        return new Promise((resolve, reject) => {
            config.method = 'get';
            config.url = "/users/";
            config.params = {
                user_id: user_id
            };

            axiosInstance(config)
                .then(response => {
                    resolve(response.data.users)
                }).catch(error => {
                    reject(error)
                })
        })
    }

    getReferrals(user_id) {
        return new Promise((resolve, reject) => {
            config.method = 'get';
            config.url = "/users/referrals";
            config.params = {
                user_id: user_id
            };

            axiosInstance(config)
                .then(response => {
                    resolve(response.data.user_referrals)
                }).catch(error => {
                    reject(error.response.data)
                })
        })
    }

    getReferreds(user_id) {
        return new Promise((resolve, reject) => {
            config.method = 'get';
            config.url = "/users/referreds";
            config.params = {
                user_id: user_id
            };

            axiosInstance(config)
                .then(response => {
                    resolve(response.data.user_referreds)
                }).catch(error => {
                    reject(error)
                })
        })
    }

    getClients(user_id) {
        return new Promise((resolve, reject) => {
            config.method = 'get';
            config.url = "/users/clients";
            config.params = {
                user_id: user_id
            };

            axiosInstance(config)
                .then(response => {
                    resolve(response.data)
                }).catch(error => {
                    reject(error)
                })
        })
    }

    getTradingAccountsLogins(user_id) {
        return new Promise((resolve, reject) => {
            config.method = 'get';
            config.url = "/users/trading_accounts/logins";
            config.params = {
                user_id: user_id
            };

            axiosInstance(config)
                .then(response => {
                    resolve(response.data)
                }).catch(error => {
                    reject(error)
                })
        })
    }

    getWithdraws(user_id) {
        return new Promise((resolve, reject) => {
            config.method = 'get';
            config.url = "/users/withdraws";
            config.params = {
                user_id: user_id
            };

            axiosInstance(config)
                .then(response => {
                    resolve(response.data)
                }).catch(error => {
                    reject(error)
                })
        })
    }

    getSumsubs(user_id) {
        return new Promise((resolve, reject) => {
            config.method = 'get';
            config.url = "/users/sumsubs";
            config.params = {
                user_id: user_id
            };

            axiosInstance(config)
                .then(response => {
                    resolve(response.data)
                }).catch(error => {
                    reject(error)
                })
        })
    }
}

export default new UserService();