<template>
    <li class="menu">
        <a class="dropdown-toggle" data-bs-toggle="collapse" data-bs-target="#trading_accounts" aria-controls="trading_accounts" aria-expanded="false">
            <div class="">
                <svg width="800px" height="800px" viewBox="0 0 1024 1024" class="icon" version="1.1" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M810.666667 85.333333h42.666666v426.666667h-42.666666zM320 149.333333h42.666667v362.666667h-42.666667zM170.666667 576h42.666666v362.666667H170.666667zM597.333333 405.333333h42.666667v469.333334h-42.666667z"
                        fill="#546E7A"
                    />
                    <path
                        d="M768 149.333333h128c23.466667 0 42.666667 19.2 42.666667 42.666667v213.333333c0 23.466667-19.2 42.666667-42.666667 42.666667h-128c-23.466667 0-42.666667-19.2-42.666667-42.666667V192c0-23.466667 19.2-42.666667 42.666667-42.666667zM277.333333 213.333333h128c23.466667 0 42.666667 19.2 42.666667 42.666667v149.333333c0 23.466667-19.2 42.666667-42.666667 42.666667h-128c-23.466667 0-42.666667-19.2-42.666666-42.666667v-149.333333c0-23.466667 19.2-42.666667 42.666666-42.666667z"
                        fill="#4CAF50"
                    />
                    <path
                        d="M128 640h128c23.466667 0 42.666667 19.2 42.666667 42.666667v149.333333c0 23.466667-19.2 42.666667-42.666667 42.666667H128c-23.466667 0-42.666667-19.2-42.666667-42.666667v-149.333333c0-23.466667 19.2-42.666667 42.666667-42.666667zM554.666667 469.333333h128c23.466667 0 42.666667 19.2 42.666666 42.666667v256c0 23.466667-19.2 42.666667-42.666666 42.666667h-128c-23.466667 0-42.666667-19.2-42.666667-42.666667V512c0-23.466667 19.2-42.666667 42.666667-42.666667z"
                        fill="#F44336"
                    />
                </svg>
                <span>Trading</span>
            </div>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-chevron-right"
            >
                <polyline points="9 18 15 12 9 6"></polyline>
            </svg>
        </a>
        <ul id="trading_accounts" class="collapse submenu list-unstyled">
            <li><router-link to="/trading-accounts/accounts">Trading Accounts</router-link></li>
        </ul>
    </li>
</template>
